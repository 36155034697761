@font-face {
	font-family: 'LEMONMILK';
	unicode-range: U+000-5FF;
	src: local('LEMONMILK'), url('/font/LEMONMILK-Bold.otf');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeue';
	unicode-range: U+000-5FF;
	src: local('HelveticaNeue'), url('/font/HelveticaNeue.ttf') format('truetype'),
		url('/font/HelveticaNeue.ttc');
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('/font/HelveticaNeue-Bold.ttf') format('truetype');
	font-style: bold;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('/font/HelveticaNeue-Light.ttf') format('truetype');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Alimama_ShuHeiTi_Bold';
	src: url('/font/Alimama_ShuHeiTi_Bold.ttf');
}

@font-face {
	font-family: 'AlimamaFangYuanTiVF-Square';
	src: url('//at.alicdn.com/wf/webfont/wR6QNmd86wu7/zIgiW07YX8S2.woff2') format('woff2'),
		url('//at.alicdn.com/wf/webfont/wR6QNmd86wu7/ZbcSqeuYDL98.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'DINAlternate';
	src: url('/font/DINAlternate-Bold.ttf');
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: HelveticaNeue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
		Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
	letter-spacing: 0.1px;
	font-family: sans-serif, SimHei;
}

p {
	line-height: 1.6em;
}

h2 {
	line-height: 1.4em;
}

.font-alimama {
	font-family: 'Alimama_ShuHeiTi_Bold', sans-serif;
}

.font-alimama-fangyuan {
	font-family: 'AlimamaFangYuanTiVF-Square', sans-serif;
}
.bg-color-main {
	background-color: #fff1e7;
}

.color-main {
	color: black;
}

.color-secondary {
	color: #333;
}

.btn-animation-small {
	hover {
		box-shadow: 4px 4px 0 #000;
		transform: translate(-2px, -2px);
		transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
	}
}

.signature-canvas {
	border: solid 1px #000;
	border-radius: 1px;
	height: 160px;
	touch-action: none;
	width: 100%;
	background-color: #fff6f6;
}

.notionRenderContainer .notion * {
	max-width: 100%;
}

.notionRenderContainer .notion {
	background-color: transparent;
}

.notionRenderContainer .notion-header-anchor {
	scroll-margin-top: 23px;
}

.notionRenderContainer .notion .notion-frame {
	border: 1px solid black;
	box-shadow: rgb(255, 241, 231) 4px 4px, rgb(0, 0, 0) 4px 4px 0px 1px;
	background: white;
}

.notionRenderContainer .notion .notion-page {
	width: 100%;
}

.notionRenderContainer .notion .notion-page-has-icon {
	padding: 96px 20px 20px 20px;
}

.notionRenderContainer .notion .notion-page-no-icon {
	padding: 20px;
}

.notionRenderContainer .notion-frame {
	padding: 0;
}

.notionRenderContainer .notion-page-scroller {
	min-height: auto;
}

.notionRenderContainer .notion .notion-page-no-cover {
	margin-top: 0 !important;
}

.notionRenderContainer .notion-code {
	background-color: rgb(247, 246, 243);
}

.notionRenderContainer .language-shell {
	color: black;
}

.notionRenderContainer .language-plain {
	color: black;
}

.wiki-category-swiper .swiper-wrapper {
	flex-direction: column;
}

.wiki-category-swiper .swiper-slide {
	margin-bottom: 24px;
}

/* tailwind */

/* Flexbox Container */
.flex {
	display: flex;
}

/* Flex Direction */
.flex-row {
	flex-direction: row;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-col {
	flex-direction: column;
}

.flex-col-reverse {
	flex-direction: column-reverse;
}

/* Flex Wrap */
.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

/* Align Items (Cross Axis Alignment) */
.items-start {
	align-items: flex-start;
}

.items-end {
	align-items: flex-end;
}

.items-center {
	align-items: center;
}

.items-baseline {
	align-items: baseline;
}

.items-stretch {
	align-items: stretch;
}

/* Justify Content (Main Axis Alignment) */
.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

/* Flex Child */
.flex-auto {
	flex: 1 1 auto;
}

.flex-initial {
	flex: 0 1 auto;
}

.flex-none {
	flex: none;
}

.flex-1 {
	flex: 1 1 0%;
}

.flex-[number] {
	flex: [number];
	/* Replace [number] with desired value */
}

/* Gap */
.gap {
	gap: 1rem;
	/* Replace 1rem with your desired gap size */
}

.gap-x {
	column-gap: 1rem;
	/* Replace 1rem with your desired horizontal gap size */
}

.gap-y {
	row-gap: 1rem;
	/* Replace 1rem with your desired vertical gap size */
}

/* Background Color */
.bg {
	background-color: #fff1e7;
}

.bg-white {
	background-color: white;
}

.bg-lighter {
	background-color: #fafafa;
}

/* Spacing */
.pt-1 {
	padding-top: 4px;
}

.p-0 {
	padding: 0;
}

.p-1 {
	padding: 4px;
}

/* Boarder */
.rounded {
	border-radius: 4px;
}
